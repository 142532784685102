<template>
  <div>
    <div class="tracks-container">
      <div class="tracks"></div>
      <div :id="`track-${track}`" class="circle" v-for="(track, index) in tracksArr" :key="index">
        <span class="ball">{{ String(track.value).replace(".0", "") }}</span>

        <div class="inputs mt-2 mx-1 p-1 bordered" v-if="!ready">
          <section v-if="type === 'yes_no' || type === 'include'">
            <span class="d-block text-center">
              {{ track.max > 1 ? "Sim" : "Não" }}
            </span>
          </section>
          <section v-else>
            <section class="text-center">
              <small v-if="index === tracksArr.length - 1">A partir de</small>
              <h5>{{ getNumberFixed(track, index) }}</h5>
              <b-form-input v-if="index !== tracksArr.length - 1" autocomplete="off" type="text"
                class="mb-1 w-100 text-center p-0 d-none" :class="isInvalid(track, index - 1) && 'is-invalid text-danger'"
                v-model="track.min" @keypress="isNumber($event)" :disabled="isDisabled(track, index)" />
            </section>

            <section class="text-center">
              <section v-if="index !== tracksArr.length - 1">
                <b-icon-arrow-down class="d-flex justify-content-center w-100  mb-1" />
              </section>
              <b-form-input :class="[`${index === tracksArr.length - 1 && 'd-none'}`]" autocomplete="off" type="text"
                class="mb-1 w-100 text-center p-0" v-model="track.max" @keypress="isNumber($event)"
                @change="handleChangeTrack(track, index)" />
            </section>
          </section>
        </div>
        <div v-else class="mt-1">
          <section v-if="type === 'yes_no' || type === 'include'">
            <span class="d-block text-center">
              {{ track.max === 1 ? "Não" : "Sim" }}
            </span>
          </section>
          <section v-else>
            <span class="d-block text-center">
              {{ formatValue(getNumberFixed(track, index)) }}
            </span>
            <b-icon-arrow-down class="d-flex justify-content-center w-100 mb-1" />
            <span class="d-block text-center">
              {{ formatValue(track.max) }}
            </span>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber } from "@/utils/validations/isNumber.js";

export default {
  props: {
    tracks: {
      type: Array,
      required: true,
    },
    ready: {
      type: Boolean,
    },
    minValue: {
      type: [Number, String],
    },
    interval: {
      type: [Number, String],
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      tracksArr: [],
      isNumber,
    };
  },
  methods: {
    getNumberFixed(track, index) {
      if (!track) {
        return;
      }
      if (index === 0) {
        return Number(track.min).toFixed(2);
      }

      return Number(track.min + 0.01).toFixed(2);
    },
    isInvalid(track, index) {
      if (index >= 0) {
        return track.min < this.tracks[index].max;
      }
      return false;
    },

    formatValue(value) {
      const val = this.$options.filters.number(value);

      switch (this.type) {
        case "percentage":
          return `${(val * 100).toFixed(2)}%`;
        case "money":
          return val.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        default:
          return val;
      }
    },

    isDisabled(track, index) {
      return index !== 0;
    },

    handleChangeTrack(track, index) {
      const changed = Number(track.max);
      const interval = Number(this.interval);

      this.tracksArr.forEach((item, i) => {
        if (changed <= this.tracksArr[i].min) {
          this.tracksArr[i].max = this.tracksArr[i].min + interval;
        }

        if (i <= index) {
          return;
        }

        this.tracksArr[i].min = Number(this.tracksArr[i - 1].max);
        this.tracksArr[i].max = this.tracksArr[i].min + interval;
      });

      this.$emit("change_track", this.tracksArr);
    },
  },
  mounted() {
    this.tracksArr = this.tracks;
  },
  watch: {
    tracks: {
      handler(tracks) {
        this.tracksArr = tracks;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.tracks-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.tracks-container::before {
  content: "";
  /* must be added when using the ::before pseudoselector */
  background-color: $border-color;
  position: absolute;
  top: 16px;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  z-index: 0;
  transition: 0.4s ease;
  width: 100%;
}

.tracks {
  background-color: $border-color;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: 0;
  transition: 0.4s ease;
}

.circle {
  z-index: 0;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;

  .inputs {
    max-width: 68px;

    input {
      height: 24px;
    }
  }

  .ball {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: $white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
}
</style>
